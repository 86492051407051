export type TParams = {
  leader_std_code?: number
  page?: number
  per_page?: number
  order_by?: string
  order_by_direction?: string
  search?: string
  enabled?: boolean
  assigned_stores?: boolean
  goal_status?: GoalsStatus
}

export enum PeriodStatus {
  OPENED = 1,
  CLOSED = 2
}

export enum PeriodType {
  GENERAL = 1,
  LEADER = 2
}

export type TGoalsPeriod = {
  id: string
  year_month: number
  period_type_enum: PeriodType
  status: PeriodStatus
  started_at: string
  finished_at: string
  createdAt: string
  updatedAt: string
  total_leader_active: string
  goals?: TGoal[]
}

export type TGoalsLeadersListItem = {
  leader_std_code: number
  leader_user_name: string
  profile_image_file_name?: string
  network: {
    network_code?: string
    network_name?: string
  }
  subsidiary: {
    subsidiary_name?: string
    subsidiary_code?: string
  }
  total_specialists: number
  total_goal: number
}

export type TGoalsStaffListItem = {
  user_std_code: number
  user_name: string
  occupation: string
  profile_image_file_name: string
  goal: string
  udpated_goal: string
  udpated_by: string
  last_update: string
}

export enum GoalsStatus {
  ACTIVED = 1,
  CONCLUDED = 2,
  REACTIVED = 3,
  CLOSED = 4
}

export const GoalsStatusLabels: { [key in GoalsStatus]: string } = {
  [GoalsStatus.ACTIVED]: 'Ativo',
  [GoalsStatus.CLOSED]: 'Fechado',
  [GoalsStatus.REACTIVED]: 'Ativo',
  [GoalsStatus.CONCLUDED]: 'Concluído'
}

export type TGoalsLeaderDetails = {
  started_at: string
  finished_at: string
  leader_std_code: number
  leader_user_name: string
  occupation: string
  profile_image_file_name?: string
  network: {
    network_code: string
    network_name: string
  }
  subsidiary: {
    subsidiary_name: string
    subsidiary_code: string
  }
  status: GoalsStatus
  goals?: TGoal[]
}

export type TOpenGoalsPeriodParams = {
  date_start: string
  date_end: string
  leader_std_code?: number
  goals?: {
    production?: number
    insurance?: number
    insurance_auto?: number
    webmotors?: number
    charge?: number
    more_accounts?: number
  }
}

export type AdjustmentItem = {
  yearMonth: number
  sentGoal: number
  adjustedGoal: number
  variance: number
  adjustmentDate: string
  adjustedBy: string
}

export type TGoalAdjustment = {
  consultantName: string
  role: string
  consultantId: string
  reference: AdjustmentItem
  history: AdjustmentItem
}

export type TAdjustments = {
  type: string
  yearMonth: number
  goal: number
  adjustments: TGoalAdjustment[]
}

export type TReportHeader = {
  leaderStdCode?: number
  leaderName?: string
  currentDate?: string
  yearMonth?: number
  startedAt?: string
  finishedAt?: string
}

export type TGoalsReport = {
  file_url?: string
  file_name?: string
  header?: TReportHeader
  goal_adjustments?: TAdjustments[]
}

export type TGoal = {
  id: string
  title: string
  value: number
  percent?: string
  delta?: number
}
