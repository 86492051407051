import React from 'react'

import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TGoalAdjustment } from 'integration/resources/goals'
import {
  correctDate,
  formatDate,
  formatReferenceMonth
} from 'src/screens/WalletsManagement/Components/ReportPDF/utils'

export const GoalAdjustmentCard: React.FC<TGoalAdjustment> = ({
  consultantName,
  role,
  consultantId,
  reference,
  history
}) => (
  <View style={styles.container} wrap={false}>
    <View style={styles.header}>
      <Text style={styles.consultantName}>{consultantName}</Text>
      <Text style={styles.roleInfo}>
        {role} - matrícula {consultantId}
      </Text>
    </View>

    <View style={styles.row}>
      <View style={styles.block}>
        <Text style={styles.blockTitle}>
          Mês de referência: {formatReferenceMonth(correctDate(String(reference.yearMonth)))}
        </Text>
        <View style={styles.divider} />
        <View style={styles.textRow}>
          <Text style={styles.label}>Meta enviada:</Text>
          <Text style={styles.value}>
            R${reference.sentGoal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Meta ajustada:</Text>
          <Text style={styles.value}>
            R${reference.adjustedGoal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} (
            {reference.variance > 0 ? '+' : ''}
            {reference.variance}%)
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Data do último ajuste:</Text>
          <Text style={styles.value}>
            {formatDate(reference.adjustmentDate ?? '', "dd'/'MM'/'yyyy h:mm a")}
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Ajustado por:</Text>
          <Text style={styles.value}>{reference.adjustedBy}</Text>
        </View>
      </View>

      <View style={styles.block}>
        <Text style={styles.blockTitle}>
          Histórico de meta: {formatReferenceMonth(correctDate(String(history.yearMonth)))}
        </Text>
        <View style={styles.divider} />
        <View style={styles.textRow}>
          <Text style={styles.label}>Meta enviada:</Text>
          <Text style={styles.value}>
            R${history.sentGoal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Meta ajustada:</Text>
          <Text style={styles.value}>
            R${history.adjustedGoal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} (
            {history.variance > 0 ? '+' : ''}
            {history.variance}%)
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Data do último ajuste:</Text>
          <Text style={styles.value}>
            {formatDate(history.adjustmentDate ?? '', "dd'/'MM'/'yyyy h:mm a")}
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Ajustado por:</Text>
          <Text style={styles.value}>{history.adjustedBy}</Text>
        </View>
      </View>
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    border: '1px solid #DBDBDB',
    borderRadius: 10,
    padding: 8,
    paddingTop: 16,
    marginBottom: 16,
    break: 'page'
  },
  header: {
    flexDirection: 'row',
    gap: 4,
    marginBottom: 4
  },
  consultantName: {
    fontFamily: 'Assistant',
    fontWeight: 'bold',
    fontSize: 12,
    color: '#4D4D4D'
  },
  roleInfo: {
    fontFamily: 'Assistant',
    fontWeight: 'normal',
    fontSize: 12,
    color: '#4D4D4D'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 4
  },
  block: {
    flex: 1,
    paddingVertical: 6,
    paddingHorizontal: 9,
    backgroundColor: '#FAFAFA',
    borderRadius: 4,
    gap: 6
  },
  blockTitle: {
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
    fontSize: 10,
    color: '#0D0D0D'
  },
  textRow: {
    flexDirection: 'row',
    gap: 4
  },
  label: {
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
    fontSize: 10,
    color: '#0D0D0D'
  },
  value: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
    fontSize: 10,
    color: '#0D0D0D'
  },
  divider: {
    height: 1,
    backgroundColor: '#DBDBDB',
    borderRadius: 10
  }
})
