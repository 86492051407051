import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const DecreaseIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 12 12', ...props }}>
    <Path
      fillRule="evenodd"
      d="M0 6a.5.5 0 0 1 .5-.5h10.8a.5.5 0 0 1 0 1H.5A.5.5 0 0 1 0 6Z"
      clipRule="evenodd"
      fill="currentColor"
    />
  </Icon>
)
