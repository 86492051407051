import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TReportHeader } from 'integration/resources/goals'
import { TurboIcon } from 'src/screens/WalletsManagement/Components/ReportPDF/TurboIcon'
import {
  correctDate,
  formatDayDate,
  formatReferenceMonth
} from 'src/screens/WalletsManagement/Components/ReportPDF/utils'

export const Header: React.FC<{ header?: TReportHeader }> = ({ header }) => (
  <View style={styles.headerContainer}>
    <View style={styles.logoContainer}>
      <TurboIcon size="sm" />
    </View>

    <View style={styles.divider} />

    <View style={styles.headerTextContainer}>
      <Text style={styles.headerTitle}>Relatório de ajuste de metas</Text>
      <Text style={styles.headerSubtitle}>
        {`Extraído do Portal Mais Turbo / ${formatDayDate}`}
      </Text>
      <Text style={styles.headerSubtitle}>
        Mês de referência:
        {formatReferenceMonth(correctDate(String(header?.yearMonth)))}
      </Text>
    </View>
  </View>
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
    backgroundColor: '#FFFFFF'
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: 20
  },
  divider: {
    width: 1,
    height: 42,
    backgroundColor: '#DBDBDB',
    marginHorizontal: 16
  },
  headerTextContainer: {
    textAlign: 'left'
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderBottom: '1px solid #EEE',
    paddingBottom: 10,
    marginBottom: 20
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 4
  },
  headerSubtitle: {
    fontSize: 8,
    color: '#000'
  }
})
