import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import {
  TGoalsStaffListItem,
  useGetGoalsStaffListInfinityQuery,
  useGetGoalsLeaderDetailsQuery,
  GoalsStatus,
  useReactivateGoalsPeriodMutation,
  useGetGoalsReportQuery,
  useConcludeGoalsManagementMutation
} from 'integration/resources/goals'
import { useBreakpointValue, useDisclose, useToast } from 'native-base'
import { Toast } from 'organisms'
import useDebounce from 'src/hooks/useDebounce'
import { useAuthAtomValue } from 'src/store/auth'

import { Lists, OrderField, UseGoalsLeaderDetailsScreen } from './GoalsLeaderDetailsScreen.types'
import { ReportPDF } from '../Components/ReportPDF/ReportPDF'

const mergeGoalsLeadersInfiniteQuery = (
  data: ReturnType<typeof useGetGoalsStaffListInfinityQuery>['data']
) =>
  data?.pages.reduce<TGoalsStaffListItem[]>(
    //@ts-ignore
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Valor da meta',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o Menor' }
    ]
  }
]

export const useGoalsLeaderDetailsScreen: UseGoalsLeaderDetailsScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const leaderStdCode = route?.params?.userStdCode

  const authAtomValue = useAuthAtomValue()

  const isAdmin = useMemo(
    () => !!(authAtomValue?.user?.is_admin && leaderStdCode),
    [authAtomValue?.user?.is_admin, leaderStdCode]
  )

  const linkTo = useLinkTo()

  const handleGoBack = () => linkTo('/metas-admin')

  const userStdCode = useMemo(
    () => authAtomValue?.user?.std_code ?? 0,
    [authAtomValue?.user?.std_code]
  )

  const fakeStatus = useMemo(
    () => Number(route?.params?.status ?? GoalsStatus.CLOSED),
    [route?.params?.status]
  )

  const { data: leaderDetailsData, isLoading: leaderDetailsIsLoading } =
    useGetGoalsLeaderDetailsQuery({
      leader_std_code: Number(leaderStdCode) ?? userStdCode,
      goal_status: fakeStatus
    })

  const detailsData = leaderDetailsData?.data.data

  const goalsIsOpen = detailsData?.status === GoalsStatus.ACTIVED

  const [currentList, setCurrentList] = useState<Lists>(Lists.Production)

  const handleListChange = (list: Lists) => {
    setCurrentList(list)
  }

  const [search, onSearch] = useState<string>('')

  const debouncedSearch = useDebounce(search, 500)

  const handleSearchClear = () => {
    onSearch('')
  }

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const [appliedOrderBy, setAppliedOrderBy] = useState<
    'leader_user_name' | 'total_stores' | undefined
  >()

  const [appliedOrderByDirection, setAppliedOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >()

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  const handleApply = () => {
    setAppliedOrderBy(
      selectedOrderBy?.field
        ? selectedOrderBy?.field === 'Nome'
          ? 'leader_user_name'
          : 'total_stores'
        : undefined
    )

    setAppliedOrderByDirection(selectedOrderBy?.direction)
  }

  const {
    data: staffListData,
    isLoading: staffListLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetGoalsStaffListInfinityQuery({
    order_by: appliedOrderBy,
    order_by_direction: appliedOrderByDirection,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const staffList = useMemo(() => mergeGoalsLeadersInfiniteQuery(staffListData), [staffListData])

  const staffListTotalItems = staffList.length

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const handleGoToOpenGoalsPeriod = useCallback(() => {
    navigation.navigate('OpenGoalsPeriod', {
      leaderStdCode: String(leaderStdCode)
    })
  }, [navigation, leaderStdCode])

  const {
    isOpen: reactivateModalIsOpen,
    onClose: reactivateModalOnClose,
    onOpen: reactivateModalOnOpen
  } = useDisclose()

  const toast = useToast()

  const [timeouts, setTimeouts] = useState<number[]>([])

  useEffect(() => {
    return () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId))
    }
  }, [timeouts])

  const { mutate: reactivateMutate, isLoading: reactivateIsLoading } =
    useReactivateGoalsPeriodMutation()

  const handleReactivate = useCallback(() => {
    const clearAllTimeouts = () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId))

      setTimeouts([])
    }

    reactivateMutate(
      {
        leaderStdCode: leaderStdCode ? Number(leaderStdCode) : userStdCode
      },
      {
        onError() {
          const timeoutId = window.setTimeout(() => {
            clearAllTimeouts()
          }, 1000)

          setTimeouts((prev) => [...prev, timeoutId])

          reactivateModalOnClose()

          navigation.setParams({ status: GoalsStatus.REACTIVED })

          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação " />,
            placement: 'bottom',
            duration: 1500
          })
        },
        onSuccess() {
          const timeoutId = window.setTimeout(() => {
            clearAllTimeouts()
          }, 1000)

          reactivateModalOnClose()

          navigation.setParams({ status: GoalsStatus.REACTIVED })

          setTimeouts((prev) => [...prev, timeoutId])

          toast.show({
            render: () => <Toast type="success" text="Atualização efetuada com sucesso 🎉" />,
            placement: 'bottom',
            duration: 1500
          })
        }
      }
    )
  }, [
    navigation,
    leaderStdCode,
    reactivateMutate,
    timeouts,
    toast,
    userStdCode,
    reactivateModalOnClose
  ])

  const {
    isOpen: concludeModalIsOpen,
    onClose: concludeModalOnClose,
    onOpen: concludeModalOnOpen
  } = useDisclose()

  const { mutate: concludeMutate, isLoading: concludeIsLoading } =
    useConcludeGoalsManagementMutation()

  const handleConclude = useCallback(() => {
    const clearAllTimeouts = () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId))

      setTimeouts([])
    }

    concludeMutate(
      {
        leader_std_code: leaderStdCode ? Number(leaderStdCode) : userStdCode
      },
      {
        onError() {
          const timeoutId = window.setTimeout(() => {
            clearAllTimeouts()
          }, 1000)

          setTimeouts((prev) => [...prev, timeoutId])

          concludeModalOnClose()

          navigation.setParams({ status: GoalsStatus.CONCLUDED })

          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação " />,
            placement: 'bottom',
            duration: 1500
          })
        },
        onSuccess() {
          const timeoutId = window.setTimeout(() => {
            clearAllTimeouts()
          }, 1000)

          concludeModalOnClose()

          navigation.setParams({ status: GoalsStatus.CONCLUDED })

          setTimeouts((prev) => [...prev, timeoutId])

          toast.show({
            render: () => <Toast type="success" text="Atualização efetuada com sucesso 🎉" />,
            placement: 'bottom',
            duration: 1500
          })
        }
      }
    )
  }, [
    navigation,
    leaderStdCode,
    concludeMutate,
    timeouts,
    toast,
    userStdCode,
    concludeModalOnClose
  ])

  const { data: goalsReportData, isLoading: goalsReportIsLoading } = useGetGoalsReportQuery({
    leader_std_code: Number(leaderStdCode),
    enabled: true
  })

  //@ts-ignore
  const reportData = goalsReportData?.data.data

  const [downloadReportIsLoading, setDownloadReportIsLoading] = useState(false)

  const handleDownloadReport = useCallback(async () => {
    setDownloadReportIsLoading(true)

    try {
      const blob = await pdf(<ReportPDF {...reportData} />).toBlob()

      const file = blob

      saveAs(file, 'Relatorio Ajuste de Metas.pdf')
    } catch (error) {
      console.error('Erro ao gerar o PDF:', error)
    }

    setDownloadReportIsLoading(false)
  }, [reportData])

  const anyHookIsLoading =
    leaderDetailsIsLoading ||
    staffListLoading ||
    reactivateIsLoading ||
    downloadReportIsLoading ||
    goalsReportIsLoading ||
    concludeIsLoading

  return {
    isMobile,
    isAdmin,
    handleGoBack,
    detailsData,
    leaderDetailsIsLoading,
    goalsIsOpen,
    currentList,
    handleListChange,
    staffListData: staffList,
    staffListIsLoading: staffListLoading,
    staffListIsFetchingNextPage: isFetchingNextPage,
    staffListTotalItems,
    onEndReached,
    search,
    onSearch,
    onPressSearchClear: handleSearchClear,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    handleGoToOpenGoalsPeriod,
    reactivateModalIsOpen,
    reactivateModalOnClose,
    reactivateModalOnOpen,
    handleReactivate,
    reactivateIsLoading,
    anyHookIsLoading,
    handleDownloadReport,
    downloadReportIsLoading,
    concludeModalIsOpen,
    concludeModalOnClose,
    concludeModalOnOpen,
    handleConclude,
    concludeIsLoading
  }
}
