import { Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { FlameIcon } from 'src/screens/WalletsManagement/Components/ReportPDF/FlameIcon'
import { TurboIcon } from 'src/screens/WalletsManagement/Components/ReportPDF/TurboIcon'
import {
  formatDate,
  formatDayDate,
  formatReferenceMonth
} from 'src/screens/WalletsManagement/Components/ReportPDF/utils'

type TCoverPageProps = {
  referenceMonth?: Date
  mainLeader?: string
  periodStartDate?: string
  periodEndDate?: string
}

export const CoverPage: React.FC<TCoverPageProps> = ({
  referenceMonth,
  mainLeader,
  periodStartDate,
  periodEndDate
}) => (
  <Page size="A4" style={[styles.page, styles.coverPage]}>
    <View style={styles.logoContainer}>
      <TurboIcon size="md" />
    </View>

    <FlameIcon />

    <View style={styles.header}>
      <Text style={styles.title}>Relatório de Ajuste de{'\n'}Metas</Text>
      <Text style={styles.subtitle}>{formatDayDate}</Text>
      <Text style={[styles.subtitle, styles.subtitleMini]}>
        {'\n'}Extraído do Portal Mais Turbo
      </Text>
      <Text style={[styles.subtitle, styles.subtitleMini]}>Líder responsável {mainLeader}</Text>
    </View>

    <View style={styles.footerContainer}>
      <Text style={styles.footerText}>
        <Text style={styles.footerTextBold}>Mês de referência:</Text>{' '}
        {formatReferenceMonth(referenceMonth)}
      </Text>

      <Text style={styles.footerText}>
        <Text style={styles.footerTextBold}>Período da Meta: </Text>
        {formatDate(periodStartDate ?? '')} a {formatDate(periodEndDate ?? '')}
      </Text>

      <Text style={styles.footerText}>
        <Text style={styles.footerTextBold}>Líder responsável:</Text> {mainLeader}
      </Text>
    </View>
  </Page>
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
    backgroundColor: '#FFFFFF'
  },
  coverPage: {
    padding: 0,
    paddingTop: 80
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: 20
  },
  header: {
    textAlign: 'center'
  },
  title: {
    fontSize: 24,
    color: '#F3123C',
    marginBottom: 16,
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 14,
    color: 'gray'
  },
  subtitleMini: {
    fontSize: 10
  },
  footerContainer: {
    position: 'absolute',
    bottom: 40,
    left: 40,
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 4
  },
  footerText: {
    fontSize: 10,
    color: '#737373',
    lineHeight: 1.5
  },
  footerTextBold: {
    fontWeight: 'bold'
  }
})
