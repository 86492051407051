import { AcademyIcon as Academy } from './AcademyIcon'
import { AccordionArrowIcon as AccordionArrow } from './AccordionArrowIcon'
import { AddIcon as Add } from './AddIcon'
import { AlertIcon as Alert } from './AlertIcon'
import { AllIcon as All } from './AllIcon'
import { AndroidLogoIcon as AndroidLogo } from './AndroidLogoIcon'
import { AppleLogoIcon as AppleLogo } from './AppleLogoIcon'
import { ArrowDropDownIcon as ArrowDropDown } from './ArrowDropDownIcon'
import { ArrowIcon as Arrow } from './ArrowIcon'
import { ArrowLeftGoIcon as ArrowLeftGo } from './ArrowLeftIcon'
import { ArrowRightGoIcon as ArrowRightGo } from './ArrowRightGoIcon'
import { ArrowUpIcon as ArrowUp } from './ArrowUpIcon'
import { AttachmentIcon as Attachment } from './AttachmentIcon'
import { AttentionIcon as Attention } from './AttentionIcon'
import { AwaitingIcon as Awaiting } from './AwaitingIcon'
import { BellIcon as Bell } from './BellIcon'
import { BookmarkIcon as Bookmark } from './BookmarkIcon'
import { BookOpenIcon as BookOpen } from './BookOpenIcon'
import { CalendarAttentionIcon as CalendarAttention } from './CalendarAttentionIcon'
import { CalendarHourIcon as CalendarHour } from './CalendarHourIcon'
import { CalendarIcon as Calendar } from './CalendarIcon'
import { CalendarPeriodIcon as CalendarPeriod } from './CalendarPeriod'
import { CalendarPlusIcon as CalendarPlus } from './CalendarPlusIcon'
import { CalendarWeekIcon as CalendarWeek } from './CalendarWeekIcon'
import { CameraIcon as Camera } from './CameraIcon'
import { CampaignIcon as Campaign } from './CampaignIcon'
import { CarIcon as Car } from './CarIcon'
import { CashIcon as Cash } from './CashIcon'
import { CategoryUserIcon as CategoryUser } from './CategoryUserIcon'
import { ChangeOrderIcon as ChangeOrder } from './ChangeOrderIcon'
import { ChargeIcon as Charge } from './ChargeIcon'
import { ChartIcon as Chart } from './ChartIcon'
import { ChatIcon as Chat } from './ChatIcon'
import { CheckConcludedIcon as CheckConcluded } from './CheckConcludedIcon'
import { CheckIcon as Check } from './CheckIcon'
import { ChevronIcon as Chevron } from './ChevronIcon'
import { ClipIcon as Clip } from './ClipIcon'
import { ClockIcon as Clock } from './ClockIcon'
import { CloseIcon as Close } from './CloseIcon'
import { CloseTabIcon as CloseTab } from './CloseTabIcon'
import { CommunicationIcon as Communication } from './CommunicationIcon'
import { ConcludedIcon as Concluded } from './ConcludedIcon'
import { ContentIcon as Content } from './ContentIcon'
import { CSVIcon as CSV } from './CSVIcon'
import { DashboardIcon as Dashboard } from './DashboardIcon'
import { DashboardsIcon as Dashboards } from './DashboardsIcon'
import { DecreaseIcon as Decrease } from './DecreaseIcon'
import { DOCIcon as DOC } from './DOCIcon'
import { DOCXIcon as DOCX } from './DOCXIcon'
import { DollarIcon as Dollar } from './DollarIcon'
import { DownloadingIcon as Downloading } from './DownloadingIcon'
import { DropdownIcon as Dropdown } from './DropdownIcon'
import { EcosystemSIMIcon as EcosystemSIM } from './EcosystemSIMIcon'
import { EcosystemTurboIcon as EcosystemTurbo } from './EcosystemTurboIcon'
import { EcosystemWebmotorsIcon as EcosystemWebmotors } from './EcosystemWebmotorsIcon'
import { EditIcon as Edit } from './EditIcon'
import { EditTextIcon as EditText } from './EditTextIcon'
import { EmailIcon as Email } from './EmailIcon'
import { ExclamationIcon as Exclamation } from './ExclamationIcon'
import { EyeIcon as Eye } from './EyeIcon'
import { FileIcon as File } from './FileIcon'
import { FilterIcon as Filter } from './FilterIcon'
import { FlameSantanderIcon as FlameSantander } from './FlameSantanderIcon'
import { FlashlightIcon as Flashlight } from './FlashlightIcon'
import { FlatAddIcon as FlatAdd } from './FlatAddIcon'
import { FullScreenIcon as FullScreen } from './FullScreenIcon'
import { GearIcon as Gear } from './GearIcon'
import { GiftIcon as Gift } from './GiftIcon'
import { GooglePlayLogoIcon as GooglePlayLogo } from './GooglePlayLogoIcon'
import { GoOutIcon as GoOut } from './GoOutIcon'
import { GroupIcon as Group } from './GroupIcon'
import { Heart } from './HeartIcon'
import { HourglassIcon as Hourglass } from './HourglassIcon'
import { InfoIcon as Info } from './InfoIcon'
import { IntegrationIcon as Integration } from './IntegrationIcon'
import { ItemValidatorIcon as Validator } from './ItemValidatorIcon'
import { JPGIcon as JPG } from './JPGIcon'
import { KanbanIcon as Kanban } from './KanbanIcon'
import { KeyIcon as Key } from './KeyIcon'
import { ListIcon as List } from './ListIcon'
import { LocationIcon as Location } from './LocationIcon'
import { LogoSantanderIcon as LogoSantander } from './LogoSantanderIcon'
import { LogoTurboIcon as LogoTurbo } from './LogoTurboIcon'
import { MacOsIcon as MacOs } from './MacOsIcon'
import { MailIcon as Mail } from './MailIcon'
import { MiniArrowRightIcon as MiniArrowRight } from './MiniArrowRightIcon'
import { Mobile } from './MobileIcon'
import { MoneyIcon as Money } from './MoneyIcon'
import { MoneyShieldIcon as MoneyShield } from './MoneyShieldIcon'
import { MoneyTurnoverIcon as MoneyTurnover } from './MoneyTurnoverIcon'
import { MoreFidelityIcon as MoreFidelity } from './MoreFidelityIcon'
import { MoreIcon as More } from './MoreIcon'
import { MP3Icon as MP3 } from './MP3Icon'
import { MP4Icon as MP4 } from './MP4Icon'
import { OrderByIcon as OrderBy } from './OrderByIcon'
import { OrganizationIcon as Organization } from './OrganizationIcon'
import { PadLockIcon as PadLock } from './PadLockIcon'
import { PanelsIcon as Panels } from './PanelsIcon'
import { PDFIcon as PDF } from './PDFIcon'
import { PhoneIcon as Phone } from './PhoneIcon'
import { Pitstop } from './PitstopIcon'
import { PlusIcon as Plus } from './PlusIcon'
import { PNGIcon as PNG } from './PNGIcon'
import { PortalMaisCompleteIcon as PortalMaisComplete } from './PortalMaisCompleteIcon'
import { PortalMaisIcon as PortalMais } from './PortalMaisIcon'
import { PortalMaisTurboIcon as PortalMaisTurbo } from './PortalMaisTurboIcon'
import { PPTIcon as PPT } from './PPTIcon'
import { PreferencesIcon as Preferences } from './PreferencesIcon'
import { ProcessIcon as Process } from './ProcessIcon'
import { ProductIcon as Product } from './ProductIcon'
import { ReadIcon as Read } from './ReadIcon'
import { RemoveAccountIcon as RemoveAccount } from './RemoveAccountIcon'
import { ReturnIcon as Return } from './ReturnIcon'
import { RoleIcon as Role } from './RoleIcon'
import { RotateIcon as Rotate } from './RotateIcon'
import { SantanderIcon as Santander } from './SantanderIcon'
import { SantanderLogoFlameIcon as SantanderLogoFlame } from './SantanderLogoFlameIcon'
import { SearchIcon as Search } from './SearchIcon'
import { ShareIcon as Share } from './ShareIcon'
import { ShieldIcon as Shield } from './ShieldIcon'
import { StarIcon as Star } from './StarIcon'
import { StoreIcon as Store } from './StoreIcon'
import { StrippedCircleIcon as StrippedCircle } from './StrippedCircleIcon'
import { SuccessIcon as Success } from './SuccessIcon'
import { SuitcaseIcon as Suitcase } from './SuitcaseIcon'
import { SummaryStatusIcon as SummaryStatus } from './SummaryStatusIcon'
import { SupportIcon as Support } from './SupportIcon'
import { SurveyIcon as Survey } from './SurveyIcon'
import { TabIcon as Tab } from './TabIcon'
import { ThumbsDownIcon as ThumbsDown } from './ThumbsDownIcon'
import { ThumbsUpIcon as ThumbsUp } from './ThumbsUpIcon'
import { TrainingIcon as Training } from './TrainingIcon'
import { TrashGhostIcon as TrashGhost } from './TrashGhostIcon'
import { TrashIcon as Trash } from './TrashIcon'
import { UnionIcon as Union } from './UnionIcon'
import { UserIcon as User } from './UserIcon'
import { ViewAllIcon as ViewAll } from './ViewAllIcon'
import { WalletIcon as Wallet } from './WalletIcon'
import { WalletsManagementIcon as WalletsManagement } from './WalletsManagementIcon'
import { WarningIcon as Warning } from './WarningIcon'
import { WatchIcon as Watch } from './WatchIcon'
import { WebsiteIcon as Website } from './WebsiteIcon'
import { WindowsIcon as Windows } from './WindowsIcon'
import { WorldIcon as World } from './WorldIcon'
import { WorldPlanet } from './WorldPlanetIcon'
import { XLSIcon as XLS } from './XLSIcon'
import { XLSMIcon as XLSM } from './XLSMIcon'
import { XLSXIcon as XLSX } from './XLSXIcon'
import { ZendeskIcon as Zendesk } from './ZendeskIcon'
import { ZIPIcon as ZIP } from './ZIPIcon'

export default {
  Academy,
  AccordionArrow,
  Add,
  Alert,
  All,
  AndroidLogo,
  AppleLogo,
  ArrowDropDown,
  Arrow,
  ArrowLeftGo,
  ArrowRightGo,
  ArrowUp,
  Attachment,
  Attention,
  Awaiting,
  Bell,
  Bookmark,
  BookOpen,
  CalendarAttention,
  CalendarHour,
  Calendar,
  CalendarPeriod,
  CalendarPlus,
  CalendarWeek,
  Camera,
  Campaign,
  Car,
  Cash,
  CategoryUser,
  ChangeOrder,
  Charge,
  Chart,
  Chat,
  CheckConcluded,
  Check,
  Chevron,
  Clip,
  Clock,
  Close,
  CloseTab,
  Communication,
  Concluded,
  Content,
  CSV,
  Dashboard,
  Dashboards,
  Decrease,
  DOC,
  DOCX,
  Dollar,
  Downloading,
  Dropdown,
  EcosystemSIM,
  EcosystemTurbo,
  EcosystemWebmotors,
  Edit,
  EditText,
  Email,
  Exclamation,
  Eye,
  File,
  Filter,
  FlameSantander,
  Flashlight,
  FlatAdd,
  FullScreen,
  Gear,
  Gift,
  GooglePlayLogo,
  GoOut,
  Group,
  Heart,
  Hourglass,
  Info,
  Integration,
  Validator,
  JPG,
  Kanban,
  Key,
  List,
  Location,
  LogoSantander,
  LogoTurbo,
  MacOs,
  Mail,
  MiniArrowRight,
  Mobile,
  Money,
  MoneyShield,
  MoneyTurnover,
  MoreFidelity,
  More,
  MP3,
  MP4,
  OrderBy,
  Organization,
  PadLock,
  Panels,
  PDF,
  Phone,
  Pitstop,
  Plus,
  PNG,
  PortalMaisComplete,
  PortalMais,
  PortalMaisTurbo,
  PPT,
  Preferences,
  Process,
  Product,
  Read,
  RemoveAccount,
  Return,
  Role,
  Rotate,
  Santander,
  SantanderLogoFlame,
  Search,
  Share,
  Shield,
  Star,
  Store,
  StrippedCircle,
  Success,
  Suitcase,
  SummaryStatus,
  Support,
  Survey,
  Tab,
  ThumbsDown,
  ThumbsUp,
  Training,
  TrashGhost,
  Trash,
  Union,
  User,
  ViewAll,
  Wallet,
  WalletsManagement,
  Warning,
  Watch,
  Website,
  Windows,
  World,
  WorldPlanet,
  XLS,
  XLSM,
  XLSX,
  Zendesk,
  ZIP
}
