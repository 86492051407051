import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OpenGoalsPeriodScreenComponent } from './OpenGoalsPeriodScreen.types'
import { useOpenGoalsPeriodScreen } from './useOpenGoalsPeriodScreen'

const OpenGoalsPeriodScreenDesktop = lazy(() => import('./OpenGoalsPeriodScreen.desktop'))

export const OpenGoalsPeriodScreen: OpenGoalsPeriodScreenComponent = ({ navigation, route }) => {
  const props = useOpenGoalsPeriodScreen({ navigation, route })

  const Screen = OpenGoalsPeriodScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
