import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import {
  TParams,
  TGoalsLeadersListItem,
  TGoalsPeriod,
  TGoalsStaffListItem,
  TGoalsLeaderDetails,
  TOpenGoalsPeriodParams,
  TGoalsReport,
  PeriodStatus,
  GoalsStatus
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_PERIOD_QUERY = 'getGoalsPeriod'

export const KEY_LEADERS_LIST_QUERY = 'getGoalsLeadersList'

export const KEY_STAFF_LIST_QUERY = 'getStaffList'

export const KEY_LEADER_DETAILS_QUERY = 'getGoalsLeaderDetails'

export const KEY_REPORT_QUERY = 'getGoalsReport'

export const useGetGoalsPeriodQuery = ({ period }: { period?: PeriodStatus }) => {
  return useQuery<AxiosResponse<ResponseSuccess<TGoalsPeriod>>, AxiosError<ResponseError>>(
    [KEY_PERIOD_QUERY, period],
    () => requests.getGoalsPeriod({ period })
  )
}

export const useGetGoalsLeadersListInfinityQuery = ({ enabled = true, ...params }: TParams) =>
  useInfiniteQuery<
    AxiosResponse<ResponseSuccess<TGoalsLeadersListItem[]>>,
    AxiosError<ResponseError>
  >(
    [KEY_LEADERS_LIST_QUERY, params],
    //@ts-ignore
    ({ pageParam }) => requests.getGoalsLeadersList({ ...params, page: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetGoalsStaffListInfinityQuery = ({ enabled = true, ...params }: TParams) =>
  useInfiniteQuery<
    AxiosResponse<ResponseSuccess<TGoalsStaffListItem[]>>,
    AxiosError<ResponseError>
  >(
    [KEY_STAFF_LIST_QUERY, params],
    //@ts-ignore
    ({ pageParam }) => requests.getGoalsStaffList({ ...params, page: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetGoalsLeaderDetailsQuery = ({
  leader_std_code,
  goal_status
}: {
  leader_std_code: number
  goal_status?: GoalsStatus
}) => {
  return useQuery<AxiosResponse<ResponseSuccess<TGoalsLeaderDetails>>, AxiosError<ResponseError>>(
    [KEY_LEADER_DETAILS_QUERY, leader_std_code, goal_status],
    () => requests.getGoalsLeaderDetails({ leader_std_code, goal_status })
  )
}

export const useOpenGoalsPeriodMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TOpenGoalsPeriodParams>>,
    AxiosError<ResponseError>,
    TOpenGoalsPeriodParams
  >(requests.openGoalsPeriod, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_PERIOD_QUERY])

      queryClient.invalidateQueries([KEY_LEADER_DETAILS_QUERY])

      queryClient.invalidateQueries([KEY_LEADERS_LIST_QUERY])
    }
  })
}

export const useReactivateGoalsPeriodMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<{ leaderStdCode: number }>>,
    AxiosError<ResponseError>,
    { leaderStdCode: number }
  >(requests.reactivateGoalsPeriod, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_LEADER_DETAILS_QUERY])

      queryClient.invalidateQueries([KEY_PERIOD_QUERY])

      queryClient.invalidateQueries([KEY_STAFF_LIST_QUERY])

      queryClient.invalidateQueries([KEY_LEADERS_LIST_QUERY])
    }
  })
}

export const useGetGoalsReportQuery = ({
  enabled = true,
  leader_std_code
}: {
  enabled?: boolean
  leader_std_code: number
}) => {
  return useQuery<AxiosResponse<ResponseSuccess<TGoalsReport>>, AxiosError<ResponseError>>(
    [KEY_REPORT_QUERY, leader_std_code],
    //@ts-ignore
    () => requests.getGoalsReport({ leader_std_code }),
    { enabled }
  )
}

export const useConcludeGoalsManagementMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<ResponseSuccess<TParams>>, AxiosError<ResponseError>, TParams>(
    requests.concludeGoalsManagement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_LEADER_DETAILS_QUERY])

        queryClient.invalidateQueries([KEY_PERIOD_QUERY])

        queryClient.invalidateQueries([KEY_STAFF_LIST_QUERY])

        queryClient.invalidateQueries([KEY_LEADERS_LIST_QUERY])
      }
    }
  )
}
