import { useCallback, useEffect, useMemo, useState } from 'react'

import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import {
  PeriodStatus,
  TGoalsLeadersListItem,
  useGetGoalsLeadersListInfinityQuery,
  useGetGoalsPeriodQuery,
  useGetGoalsReportQuery
} from 'integration/resources/goals'
import { useBreakpointValue, useDisclose } from 'native-base'
import useDebounce from 'src/hooks/useDebounce'

import { Lists, OrderField, UseGoalsLeadersListScreen } from './GoalsLeadersListScreen.types'
import { ReportPDF } from '../Components/ReportPDF/ReportPDF'

const mergeGoalsLeadersInfiniteQuery = (
  data: ReturnType<typeof useGetGoalsLeadersListInfinityQuery>['data']
) =>
  data?.pages.reduce<TGoalsLeadersListItem[]>(
    //@ts-ignore
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Valor da meta',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o Menor' }
    ]
  }
]

export const useGoalsLeadersListScreen: UseGoalsLeadersListScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const fakePeriod = useMemo(() => Number(route?.params?.period), [route?.params?.period])

  const { data: periodData, isLoading: periodDataIsLoading } = useGetGoalsPeriodQuery({
    period: fakePeriod
  })

  const periodIsOpen = periodData?.data.data.status === PeriodStatus.OPENED

  const [currentList, setCurrentList] = useState<Lists>(Lists.Production)

  const handleListChange = (list: Lists) => {
    setCurrentList(list)
  }

  const [search, onSearch] = useState<string>('')

  const debouncedSearch = useDebounce(search, 500)

  const handleSearchClear = () => {
    onSearch('')
  }

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const [appliedOrderBy, setAppliedOrderBy] = useState<
    'leader_user_name' | 'total_stores' | undefined
  >()

  const [appliedOrderByDirection, setAppliedOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >()

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  const handleApply = () => {
    setAppliedOrderBy(
      selectedOrderBy?.field
        ? selectedOrderBy?.field === 'Nome'
          ? 'leader_user_name'
          : 'total_stores'
        : undefined
    )

    setAppliedOrderByDirection(selectedOrderBy?.direction)
  }

  const {
    data: leadersListData,
    isLoading: leadersListLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetGoalsLeadersListInfinityQuery({
    order_by: appliedOrderBy,
    order_by_direction: appliedOrderByDirection,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const leadersList = useMemo(
    () => mergeGoalsLeadersInfiniteQuery(leadersListData),
    [leadersListData]
  )

  const leadersListTotalItems = leadersList.length

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const handleGoToGoalsLeaderDetails = (userStdCode: string) => {
    navigation.navigate('GoalsLeaderDetails', { userStdCode })
  }

  const handleGoToOpenGoalsPeriod = () => {
    navigation.navigate('OpenGoalsPeriod', {})
  }

  const {
    isOpen: closePeriodModalIsOpen,
    onClose: closePeriodModalOnClose,
    onOpen: closePeriodModalOnOpen
  } = useDisclose()

  const {
    isOpen: confirmClosePeriodModalIsOpen,
    onClose: confirmClosePeriodModalOnClose,
    onOpen: confirmClosePeriodModalOnOpen
  } = useDisclose()

  const handleClosePeriod = () => {
    navigation.setParams({ period: 2 })
  }

  const unadjustedLeaders = 0

  const [reportLeaderStdCode, setReportLeaderStdCode] = useState('')

  const { data: goalsReportData, isLoading: goalsReportIsLoading } = useGetGoalsReportQuery({
    leader_std_code: Number(reportLeaderStdCode),
    enabled: !!reportLeaderStdCode
  })

  //@ts-ignore
  const reportData = goalsReportData?.data.data

  const handleDownloadReport = (leaderStdCode: string) => {
    setReportLeaderStdCode(leaderStdCode)
  }

  const [downloadReportIsLoading, setDownloadReportIsLoading] = useState(false)

  const handleCreatePDF = useCallback(async () => {
    setDownloadReportIsLoading(true)

    try {
      const blob = await pdf(<ReportPDF {...reportData} />).toBlob()

      const file = blob

      saveAs(file, 'Relatorio Ajuste de Metas.pdf')
    } catch (error) {
      console.error('Erro ao gerar o PDF:', error)
    }

    setDownloadReportIsLoading(false)
  }, [reportData])

  useEffect(() => {
    if ((reportLeaderStdCode && reportData?.header?.leaderStdCode) || reportData?.file_url) {
      handleCreatePDF()
    }
  }, [reportLeaderStdCode, reportData, handleCreatePDF])

  return {
    isMobile,
    periodData: periodData?.data.data,
    periodDataIsLoading,
    periodIsOpen,
    currentList,
    handleListChange,
    leadersListData: leadersList,
    leadersListIsLoading: leadersListLoading,
    leadersListIsFetchingNextPage: isFetchingNextPage,
    leadersListTotalItems,
    onEndReached,
    search,
    onSearch,
    onPressSearchClear: handleSearchClear,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    handleGoToGoalsLeaderDetails,
    handleGoToOpenGoalsPeriod,
    closePeriodModalIsOpen,
    closePeriodModalOnClose,
    closePeriodModalOnOpen,
    confirmClosePeriodModalIsOpen,
    confirmClosePeriodModalOnClose,
    confirmClosePeriodModalOnOpen,
    handleClosePeriod,
    unadjustedLeaders,
    handleDownloadReport,
    downloadReportIsLoading: downloadReportIsLoading || goalsReportIsLoading
  }
}
