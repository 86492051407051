import { FunctionComponent } from 'react'

import { TGoalsLeadersListItem, TGoalsPeriod } from 'integration/resources/goals'
import { RootStackScreenComponentProps } from 'src/navigation'

export type GoalsLeadersListScreenComponent = FunctionComponent<GoalsLeadersListScreenProps>

export type GoalsLeadersListScreenProps = RootStackScreenComponentProps<'GoalsLeadersList'>

export type ResponsiveGoalsLeadersListScreenComponent = FunctionComponent<
  ReturnType<UseGoalsLeadersListScreen>
>

export enum Lists {
  Production,
  Insurance,
  InsuranceAuto,
  Webmotors,
  MoreAccounts,
  Charge
}

export type ListButtonProps = {
  list: Lists
  label: string
  currentList: Lists
  onPress: (list: Lists) => void
}

export type SearchBarProps = {
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  placeholder: string
}

export type OrderOption = {
  id: 'asc' | 'desc'
  name: string
}

export type OrderField = {
  label: string
  options: OrderOption[]
}

export type LeadersListProps = {
  search?: string
  leadersListData?: TGoalsLeadersListItem[]
  leadersListIsLoading: boolean
  leadersListIsFetchingNextPage: boolean
  handleGoToGoalsLeaderDetails?: (userStdCode: string) => void
  periodIsOpen: boolean
  handleDownloadReport?: (leaderStdCode: string) => void
  reportIsLoading?: boolean
}

export type LeaderCardProps = TGoalsLeadersListItem & {
  handleGoToGoalsLeaderDetails?: (userStdCode: string) => void
  generalStatusIsOpen?: boolean
  handleDownloadReport?: (leaderStdCode: string) => void
  reportIsLoading?: boolean
}

export type OrderByButtonProps = {
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear(): void
  handleApply(): void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen(): void
  orderByOnClose(): void
}

export type GoalCardProps = {
  value: string
  title: string
  percent?: string
  delta?: string
  addMarginRight?: boolean
  isLeaderCard?: boolean
}

export type UseGoalsLeadersListScreen = ({
  navigation,
  route
}: Pick<GoalsLeadersListScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  periodData?: TGoalsPeriod
  periodDataIsLoading: boolean
  periodIsOpen: boolean
  currentList: Lists
  handleListChange: (list: Lists) => void
  leadersListData?: TGoalsLeadersListItem[]
  leadersListIsLoading: boolean
  leadersListIsFetchingNextPage: boolean
  leadersListTotalItems: number
  onEndReached: () => void
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
  handleGoToGoalsLeaderDetails: (userStdCode: string) => void
  handleGoToOpenGoalsPeriod: () => void
  closePeriodModalIsOpen: boolean
  closePeriodModalOnClose(): void
  closePeriodModalOnOpen(): void
  confirmClosePeriodModalIsOpen: boolean
  confirmClosePeriodModalOnClose(): void
  confirmClosePeriodModalOnOpen(): void
  handleClosePeriod(): void
  unadjustedLeaders: number
  handleDownloadReport: (leaderStdCode: string) => void
  downloadReportIsLoading: boolean
}
