import React from 'react'

import { Document, Font } from '@react-pdf/renderer'
import { TGoalsReport } from 'integration/resources/goals'
import { correctDate } from 'src/screens/WalletsManagement/Components/ReportPDF/utils'
import { capitalizeText } from 'src/utils'

import { CoverPage } from './CoverPage'
import { GoalAdjustmentsPage } from './GoalAdjustments'
//@ts-ignore
import AssistantBold from '../../../../../assets/fonts/assistant.bold.ttf'
//@ts-ignore
import AssistantRegular from '../../../../../assets/fonts/assistant.regular.ttf'
//@ts-ignore
import OpenSansBold from '../../../../../assets/fonts/openSans.bold.ttf'
//@ts-ignore
import OpenSansRegular from '../../../../../assets/fonts/openSans.regular.ttf'

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSansRegular,
      fontWeight: 'normal'
    },
    {
      src: OpenSansBold,
      fontWeight: 'bold'
    }
  ]
})

Font.register({
  family: 'Assistant',
  fonts: [
    {
      src: AssistantRegular,
      fontWeight: 'normal'
    },
    {
      src: AssistantBold,
      fontWeight: 'bold'
    }
  ]
})

export const ReportPDF: React.FC<TGoalsReport> = ({ header, goal_adjustments }) => {
  return (
    <Document>
      <CoverPage
        referenceMonth={correctDate(String(header?.yearMonth))}
        mainLeader={capitalizeText(header?.leaderName ?? '')}
        periodStartDate={header?.startedAt}
        periodEndDate={header?.finishedAt}
      />

      {goal_adjustments?.length && (
        <GoalAdjustmentsPage header={{ ...header }} goal_adjustments={goal_adjustments} />
      )}
    </Document>
  )
}
