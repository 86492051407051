import { formatWithMask, Masks } from 'react-native-mask-input'

import { changeToCurrency } from './currencyFormat'

export const formatEmail = (email: string) =>
  `${email.substr(0, 2)}*****@****${email.substr(email.indexOf('@') + 6, email.length)}`

export const formatHidePhoneMask = (phone: string | undefined) => {
  if (phone?.includes('*')) return phone

  return `(${phone?.replace(/-|\s/g, '')?.slice(2)?.substr(0, 2)})*****-${phone?.substr(-4)}`
}

export const formatNameAttachments = (name: string) =>
  name.charAt(0).toLocaleUpperCase() + name.split('_').join(' ').slice(1).split('.')[0]

export const formatNameExtensionAttachments = (path: string) => {
  const final = path.substring(path.lastIndexOf('/') + 1)

  const separador = final.lastIndexOf('.')

  const name = separador <= 0 ? '' : final.substring(separador + 1)

  return name.split('?')[0].toLowerCase()
}

export const formatSizeAttachments = (name: string) => name.slice(1).split(' &size: ')[1]

export const formatToReal = (value: number) => {
  if (!value || value === undefined) return 'R$ 0'

  const { masked } = formatWithMask({
    text: Number(value)?.toFixed(2),
    mask: Masks.BRL_CURRENCY
  })

  return masked
}

export const decimalToPercent = (value: string | number) => {
  const formatValue = Number(value)

  return (Number(formatValue) * 100).toFixed(0) + '%'
}

export const simpleCapitalizeText = (str: string = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const sanitizeNameToFirstLetterUppercase = (name: string = '') => {
  if (name === undefined) return

  const names = name.toLowerCase().split(' ')

  if (names.length > 1 && names[1]) {
    return `${names[0].charAt(0).toUpperCase()}${names[0].substring(1)} ${names[
      names.length - 1
    ][0].toUpperCase()}${names[names.length - 1].substring(1)}`
  }

  return `${name.charAt(0).toUpperCase()}${name.substring(1).toLowerCase()}`
}

export const sanitizeObjectToNotNull = (obj: object) =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => v))

export const avatarName = (title: string) => {
  if (!title) return title

  const splitTitle = title.split(' ')

  if (splitTitle.length > 1 && splitTitle[1]) {
    return `${splitTitle[0][0]}${splitTitle[splitTitle.length - 1][0]}`.toUpperCase()
  }

  return `${title.slice(0, 2)}`.toUpperCase()
}

export const setCustomBottomHight = (elements: number) => {
  if (!elements) return '20%'

  if (elements >= 5) return '80%'

  return `${elements * 30 + 10}%`
}

export const formatPhoneNumberWithMask = (dddNumber: string, phoneNumber: string) => {
  if (!dddNumber || !phoneNumber) return ''

  const { masked: maskedPhone } = formatWithMask({
    text: `${dddNumber}${phoneNumber}`,
    mask: Masks.BRL_PHONE
  })

  return maskedPhone
}

export const formatPercentField = (text: string, isDecimal?: boolean, maxValue?: number) => {
  // Remove non-numeric characters except the decimal point and convert text to a numeric value.
  // Also, ensure that only one decimal point is present and it's correctly placed.
  let numericValue = parseFloat(text.replace(/[^0-9.]/g, '')) || 0

  // Divide by 100 to get cents if needed.
  if (isDecimal) {
    numericValue /= 10
  }

  // Use Math.max and Math.min to ensure the value is between 0 and 100.
  return Math.max(0, Math.min(maxValue ?? 100, numericValue))
}

export const formatCurrencyField = (text: string, isDecimal?: boolean) => {
  // Remove non-numeric characters and convert the text to a numeric value.
  let numericValue = parseInt(text.replace(/[^0-9]/g, ''), 10) || 0

  // Divide by 100 to get cents if needed.
  if (isDecimal) {
    numericValue /= 100
  }

  // Use Math.max and Math.min to ensure the value is between 0 and 999,999,999.
  const clampedValue = Math.max(0, Math.min(999999999.99, numericValue))

  return clampedValue
}

export const formatValueForDisplayField = (
  value: number,
  isDecimal?: boolean,
  precisionNumber?: number,
  isPercent?: boolean
) => {
  const decimalPlaces = isDecimal ? precisionNumber : 0

  // Formats a number into a string with specified decimal places for display, using Brazilian currency formatting.
  return `${value.toLocaleString('pt-BR', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  })}${isPercent ? '%' : ''}`
}

export const formatFieldLabel = (
  item: number,
  isPercent: boolean = false,
  isCurrency: boolean = false,
  needsSign: boolean = false,
  precisionNumber: number = 2,
  dontNeedsSuffix: boolean = false,
  isGoal: boolean = false
) => {
  // If isCurrency is true, format item as currency and return
  if (isCurrency) {
    let newItem = changeToCurrency(item)

    if (!needsSign) newItem = newItem.replace('R$ ', '')

    if (precisionNumber === 0) newItem = newItem.split(',')[0]

    return newItem
  }

  // If isGoal is true, format item as goal and return
  if (isGoal) {
    return `Meta ${item}`
  }

  // Format item as a percentage if isPercent is true
  const formattedItem = isPercent
    ? item.toFixed(precisionNumber ? precisionNumber : 0).replace('.', ',')
    : String(item)

  // Determine the sign (plus for positive numbers, minus for negative, or empty string)
  const sign = needsSign && item > 0 ? '+' : ''

  // Determine the suffix (percent symbol if isPercent is true, or empty string)
  const suffix = isPercent && !dontNeedsSuffix ? '%' : ''

  // Construct the label
  const label = `${sign}${formattedItem}${suffix}`

  return label || '0' // Return '0' if label is empty or falsy
}

export const formatCNPJ = (cnpj: string): string =>
  `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(
    12,
    14
  )}`

export const formatPhone = (phone: string): string => {
  if (phone.length === 11) {
    return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7, 11)}`
  }

  return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6, 10)}`
}

export const formatCEP = (cep: string): string => `${cep.slice(0, 5)}-${cep.slice(5, 8)}`

export const capitalizeText = (address: string) => {
  return address
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
